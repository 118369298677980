import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/HopeAllotment/HopeAllotment/src/layouts/pageLayout.js";
import MapContainer from "../../src/components/mapcontainer";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <MapContainer selectedPlace={{
      lat: 53.41561155973524,
      lng: -1.442938707955667
    }} mdxType="MapContainer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      